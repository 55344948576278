import React, { useState } from 'react';

const DisplayWinner = (props) => {
    const [showFullKtp, setShowFullKtp] = useState(false);

    const toggleKtp = () => {
        setShowFullKtp(!showFullKtp);
    };

    return (
        <div className='col-md-3'>
            <div className="card mb-3 shadow-sm">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-md-12 d-flex flex-column justify-content-center position-relative">
                            <button className="btn btn-danger position-absolute top-0 end-0" style={{ zIndex: 1 }} onClick={props.onDelete}>x</button>
                            <h3 className="text-center mb-0" style={{ fontSize: '5rem' }}>{props && props.kode}</h3>
                            <p className="text-center" style={{ fontSize: '1.5rem', lineHeight: '1rem' }}>{props && props.nama}</p>
                            <p className="text-center" style={{ fontSize: '1.5rem', lineHeight: '1rem', fontWeight: 800 }} onClick={toggleKtp}>
                                {props && (showFullKtp ? props.ktp : 'XXXXXX' + props.ktp.slice(6))}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DisplayWinner;

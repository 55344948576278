// src/components/Undian.js

import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import '../counter.css';
import $$ from 'jquery';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';


const Undian = () => {
    const { camaba,pengunjung } = useParams()
    const navigate = useNavigate()
    const [kodePeserta, setKodePeserta] = useState({})
    const [bisaSimpan, setBisaSimpan] = useState(false)
    const [showSetting, setShowSetting] = useState(false)
    const [settingInput, setSettingInput] = useState({})
    let myHeaders;

    const pageStyle = {
        backgroundImage: settingInput ? `url('https://pameran.apps.unismuh.ac.id/uploads/${settingInput.latar}')` : 'url(back_4.png)',
        minHeight:'100vh',
        padding: '20px',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
    };

    const fetchData = () => {
        myHeaders = new Headers();
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };    
        fetch("https://pameran.apps.unismuh.ac.id/peserta.php?aksi=winner", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log("awal",result);
            if (result.result === 'ok') {
                setKodePeserta(result.masterdata[0])
                console.log("ada");
            } else {
                console.log("kosong");
            }
        })
        .catch(error => console.log('error', error));



    }

    const fetchSetting = () => {
        myHeaders = new Headers();
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };    
        fetch("https://pameran.apps.unismuh.ac.id/peserta.php?aksi=settingapp", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result);
            setSettingInput(result)
        })
        .catch(error => console.log('error', error));        
    }


    React.useEffect(() => {
        fetchData();
        fetchSetting();
    }, [])   
    

    const sound = new Audio() 
    sound.src = `${process.env.PUBLIC_URL}\load3.mp3`; 

    const reset = () => {
        window.location.reload()
    }
    const putar = () => { 
        sound.play() 
        const numberString = kodePeserta.kode_peserta.toString();
        $$('.numbers__window__digit--1').css('animation-play-state','running')
        setTimeout(() => {
            $$('.numbers__window__digit--1').css('animation-play-state','paused')
            // for (let i = 0; i < numberString.length; i++) {
            //     const currentDigit = numberString[i];
            //     console.log(currentDigit);
            //     $$('span#digit-'+currentDigit).attr('data-fake',currentDigit+angkaAcak(parseInt(currentDigit)))
            //     $$('span#digit-'+currentDigit).data('fake',currentDigit+angkaAcak(parseInt(currentDigit)))
            // }

            $$('span#digit-1').attr('data-fake',numberString[0]+angkaAcak(numberString[0]))
            $$('span#digit-2').attr('data-fake',numberString[1]+angkaAcak(numberString[1]))
            $$('span#digit-3').attr('data-fake',numberString[2]+angkaAcak(numberString[2]))
            $$('span#digit-4').attr('data-fake',numberString[3]+angkaAcak(numberString[3]))
            $$('span#digit-5').attr('data-fake',numberString[4]+angkaAcak(numberString[4]))
            $$('span#digit-6').attr('data-fake',numberString[5]+angkaAcak(numberString[5]))
            $$('span#digit-7').attr('data-fake',numberString[6]+angkaAcak(numberString[6]))
            $$('span#digit-8').attr('data-fake',numberString[7]+angkaAcak(numberString[7]))
            $$('.tombol-reset').css('display','')
            $$('.tombol-mulai').css('display','none')
            setBisaSimpan(true)
        }, 12000);

    }     

    const angkaAcak = (buangAngka) => {
        const digits = Array.from({ length: 10 }, (_, i) => i); // Array dari 0 sampai 9
        const unwantedDigit = buangAngka;
      
        const filteredDigits = digits.filter(digit => digit !== unwantedDigit);
      
        for (let i = filteredDigits.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [filteredDigits[i], filteredDigits[j]] = [filteredDigits[j], filteredDigits[i]];
        }
      
        const randomNumber = filteredDigits.slice(0, 8).join('');
        return randomNumber;        
    }


    const simpan = async (event) => {
        if (bisaSimpan) {
            Swal.fire({
                title: "Anda yakin ?",
                text: "Data yang disimpan tidak dapat diubah",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Saya yakin",
                cancelButtonText: "Batal"
            }).then((result) => {
                if (result.isConfirmed) {
    
                    try{
    
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                        
                        var urlencoded = new URLSearchParams();
                        urlencoded.append("aksi", "juara");
                        urlencoded.append("kode_peserta", kodePeserta.kode_peserta);
                        
                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: urlencoded,
                            redirect: 'follow'
                        };
                        
                        fetch("https://pameran.apps.unismuh.ac.id/peserta.php", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            console.log("peserta",result);            
                        })
                        .catch(error => console.log('error', error)); 
                    
                    } catch (error) {
                        console.log(error);
                    }            
    
                    Swal.fire({
                    title: "Sukses",
                    text: "Data berhasil disimpan",
                    icon: "success"
                    });
                }
            });
        } else {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Belum ada pemenang !",
                showConfirmButton: false,
                timer: 2000
            });            
        }


    }

    const settingOpen = () => {
        setShowSetting(true)
    }
    const settingClose = () => {
        setShowSetting(false)
    }

    const setSettingForm = (e) => {
        console.log("e.target.name",e.target.name);
        switch (e.target.name) {
            case 'latar':
                setSettingInput({
                    ...settingInput,
                    [e.target.name]: e.target.files[0],
                });        
            break;
            default:
                setSettingInput({
                    ...settingInput,
                    [e.target.name]: e.target.value,
                });        
            break;
        }

        console.log("settingInput",settingInput);
    }

    const settingSave = () => {
        console.log("settingInput",settingInput);
        var formdata = new FormData();
        Object.entries(settingInput).map(([key, value]) =>
            formdata.append(key,value)
        );           
        formdata.append('aksi','setting');     
        formdata.append('subaksi','simpan');     
        
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: formdata,
            redirect:'follow'
        };        
        try {
            fetch('https://pameran.apps.unismuh.ac.id/peserta.php',requestOptions).then(async (resp)=>(resp.json()).then(data=>{
                console.log("data",data);
                fetchSetting();
                Swal.fire({
                    title: "Sukses",
                    text: "Data berhasil disimpan",
                    icon: "success"
                });

            }))
        } catch (error) {
            Swal.showValidationMessage(`
              Request failed: ${error}
            `);
        }        
    }

  return (
    <>
        <div style={pageStyle} className="container-fluid">        
            <div className="row">
                <div className="col-md-1 mb-2 tombol-reset"  style={{display:'none'}}>
                    <button className='btn btn-danger w-100' onClick={reset}>RESET</button>                                
                </div>
                <div className="col-md-1 mb-2 tombol-mulai" style={{display:''}}>
                    <button className='btn btn-primary w-100' onClick={putar}>MULAI</button>                                
                </div>
                <div className="col-md-1 mb-2">
                    <button className='btn btn-success w-100' onClick={simpan}>SIMPAN</button>                                
                </div>
                <div className="col-md-1 mb-2 mr-3 ms-auto">
                    <button className='btn btn-success w-100' onClick={settingOpen}>SETTING</button>                                
                </div>
                <div className="col-md-1 mb-2 mr-3">
                    <button className='btn btn-warning w-100' onClick={()=>navigate('/')}>BERANDA</button>                                
                </div>
            </div>

            <div className="row">
                <div className="col-md-2 ms-auto mb-3 mb-md-0 order-md-first">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">PENGUNJUNG</h5>
                    <h3 className="card-text" style={{fontSize:'4rem',fontStyle:'italic'}}>{pengunjung}</h3>
                    </div>
                </div>
                </div>

                <div className="col-md-2 order-md-first">
                <div className="card">
                    <div className="card-body">
                    <h5 className="card-title">REGISTRASI</h5>
                    <h3 className="card-text" style={{fontSize:'4rem',fontStyle:'italic'}}>{camaba}</h3>
                    </div>
                </div>
                </div>
            </div>



            <div className="row" style={{ marginTop:'4rem',fontSize:'12rem' }}>
                <div className='col-md-12'>
                            <section>
                                <div className="rt-container">
                                    <div className="col-rt-12">
                                        <div className="Scriptcontent">
                                                <div className="numbers">
                                                    <div className='row'>
                                                        <div className='col-md-1'>
                                                        </div>
                                                        <div className='col-md-1'>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <span className="numbers__window">
                                                                <span id="digit-1" className="numbers__window__digit numbers__window__digit--1" data-fake="00982567">3</span>
                                                            </span>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <span className="numbers__window">
                                                                <span id="digit-2" className="numbers__window__digit numbers__window__digit--1" data-fake="06987502">0</span>
                                                            </span>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <span className="numbers__window">
                                                                <span id="digit-3" className="numbers__window__digit numbers__window__digit--1" data-fake="01762534">0</span>
                                                            </span>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <span className="numbers__window">
                                                                <span id="digit-4" className="numbers__window__digit numbers__window__digit--1" data-fake="07056421">0</span>
                                                            </span>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <span className="numbers__window">
                                                                <span id="digit-5" className="numbers__window__digit numbers__window__digit--1" data-fake="06085193">0</span>
                                                            </span>

                                                        </div>
                                                        <div className='col-md-1'>
                                                            <span className="numbers__window">
                                                                <span id="digit-6" className="numbers__window__digit numbers__window__digit--1" data-fake="06243798">0</span>
                                                            </span>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <span className="numbers__window">
                                                                <span id="digit-7" className="numbers__window__digit numbers__window__digit--1" data-fake="05384216">0</span>
                                                            </span>
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <span className="numbers__window">
                                                                <span id="digit-8" className="numbers__window__digit numbers__window__digit--1" data-fake="01927064">0</span>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                    
                                        </div>
                                    </div>
                                </div>
                            </section>

                </div>
            </div>
            {bisaSimpan && (

            <div className="row" style={{ flex: '1', marginBottom: 'auto'}}>
                <div className="col-md-2">
                </div>
                <div className="col-md-8">
                    <div className="card mb-3 shadow-sm">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-10 align-items-center">
                                        <h3 className="text-align-center" style={{fontSize:'4rem'}}>{kodePeserta && kodePeserta.nama_peserta}</h3>
                                        <p className="card-text" style={{fontSize:'3.5rem'}}>{kodePeserta && kodePeserta.jabatan}</p>
                                    </div>
                                </div>                

                            </div>
                        </div>
    
                </div>
                <div className="col-md-2">
                </div>
            </div>
            )}

            <div className="row">
                <div className="col-md-2 ms-auto">
                    <div className="card">
                        <div className="card-body">
                            SIMUT-ICT 2024
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <Modal show={showSetting} onHide={settingClose}>
            <Modal.Header closeButton>
                <Modal.Title>Setup Undian</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Latar</Form.Label>
                        <Form.Control 
                            type="file" 
                            accept='image/*' 
                            id='latar'
                            name='latar'
                            onInput={setSettingForm}
                        />                        
                    </Form.Group>
                </Form>                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={settingClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={settingSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>

    </>

  );
};

export default Undian;

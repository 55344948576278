export const generateUniqueRandomNumberWithLastDigit = (specificLastDigit) => {
    let angka_arr = [];
    for (let i = 0;i<10;i++) {
        if (i !== specificLastDigit) {
            angka_arr.push(i);
        }
    }
    const digits = angka_arr;
    let randomNumber = '';

    // Shuffle the digits array (excluding 8)
    for (let i = digits.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i);
        [digits[i], digits[j]] = [digits[j], digits[i]];
    }

    // Concatenate the first 9 shuffled digits to form the first 9 digits of the random number
    for (let i = 0; i < 9; i++) {
        randomNumber += digits[i];
    }

    // Add the specific last digit (8) to the random number
    randomNumber = specificLastDigit + randomNumber;
    return randomNumber;    
};

export const generateUniqueRandomNumber = () => {
    let angka_arr = [];
    for (let i = 0;i<10;i++) {
        angka_arr.push(i);
    }
    const digits = angka_arr;
    let randomNumber = '';

    // Shuffle the digits array (excluding 8)
    for (let i = digits.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i);
        [digits[i], digits[j]] = [digits[j], digits[i]];
    }

    // Concatenate the first 9 shuffled digits to form the first 9 digits of the random number
    for (let i = 0; i <= 9; i++) {
        randomNumber += digits[i];
    }

    return randomNumber;    
};

// src/components/Routes.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Undian from './Undian';
import UndianLocal from './UndianLocal';
import UndianMulti from './UndianMulti';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/undian/" element={<Undian />} />
        <Route path="/undianlokal/" element={<UndianLocal />} />
        <Route path="/undianmulti/" element={<UndianMulti />} />
        <Route index element={<Home />} />        
      </Routes>
    </Router>
  );
};

export default AppRoutes;

// src/components/Undian.js

import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import '../counter.css';
import $$ from 'jquery';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { generateUniqueRandomNumber,generateUniqueRandomNumberWithLastDigit } from './UniqNumber'; // Adjust the path as needed
import DisplayWinner from './DisplayWinner';
import DisplayWinnerSatu from './DisplayWinnerSatu';

const UndianMulti = () => {
    const { camaba,pengunjung } = useParams()
    const navigate = useNavigate()
    const [kodePeserta, setKodePeserta] = useState({})
    const [bisaSimpan, setBisaSimpan] = useState(false)
    const [showSetting, setShowSetting] = useState(false)
    const [settingInput, setSettingInput] = useState({})
    const [fakeNumber, setFakeNumber] = useState([])
    const [winner, setWinner] = useState([])
    let myHeaders;

  

    const pageStyle = {
        backgroundImage: settingInput ? `url('https://pameran.apps.unismuh.ac.id/uploads/${settingInput.latar}')` : 'url(back_4.png)',
        minHeight:'100vh',
        padding: '20px',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
    };

    const fetchData = () => {
        myHeaders = new Headers();
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };    
        fetch("https://pameran.apps.unismuh.ac.id/peserta.php?aksi=winnermulti", requestOptions)
        .then(response => response.json())
        .then(result => {
            setWinner(result.masterdata)
        })
        .catch(error => console.log('error', error));
    }

    const fetchSetting = () => {
        myHeaders = new Headers();
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };    
        fetch("https://pameran.apps.unismuh.ac.id/peserta.php?aksi=settingapp", requestOptions)
        .then(response => response.json())
        .then(result => {
            setSettingInput(result)
            const numbers = [];
            {Array.from({ length: result.jumlah_digit }).map((_, index) => {
                numbers.push(generateUniqueRandomNumberWithLastDigit(0));
            })}        
            setFakeNumber(numbers)
    
        })
        .catch(error => console.log('error', error));        
    }


    React.useEffect(() => {
        fetchSetting();
        // fetchData();

    }, [])   
    

    const sound = new Audio() 
    sound.src = `${process.env.PUBLIC_URL}\load6.mp3`; 

    const reset = () => {
        window.location.reload()
    }
    const putar = () => { 

        console.log(fakeNumber);
        sound.play() 
        // const numberString = kodePeserta.kode_peserta.toString();
        $$('.numbers__window__digit--1').css('animation-play-state','running')
        setTimeout(() => {
            $$('.numbers__window__digit--1').css('animation-play-state','paused')
            $$("#number_roller").hide()
            $$('.tombol-reset').css('display','')
            $$('#judul_winner').css('display','')
            $$('.tombol-mulai').css('display','none')
            setBisaSimpan(true)
            fetchData();
        }, 5000);

    }     

    const simpan = async (event) => {
        if (bisaSimpan) {
            Swal.fire({
                title: "Anda yakin?",
                text: "Data yang disimpan tidak dapat diubah. Masukkan keterangan hadiah:",
                icon: "warning",
                input: 'text',
                inputPlaceholder: 'Masukkan keterangan hadiah',
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Saya yakin",
                cancelButtonText: "Batal"
            }).then((result) => {
                if (result.isConfirmed && result.value) {
    
                    try {
    
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                        
                        var urlencoded = new URLSearchParams();
                        urlencoded.append("aksi", "juaramulti");
                        urlencoded.append("kode_peserta", JSON.stringify(winner));
                        urlencoded.append("keterangan", result.value);
                        
                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: urlencoded,
                            redirect: 'follow'
                        };
                        
                        fetch("https://pameran.apps.unismuh.ac.id/peserta.php", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            console.log("peserta", result);            
                        })
                        .catch(error => console.log('error', error)); 
                    
                    } catch (error) {
                        console.log(error);
                    }            
    
                    Swal.fire({
                        title: "Sukses",
                        text: "Data berhasil disimpan",
                        icon: "success"
                    });
                }
            });
        } else {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Belum ada pemenang!",
                showConfirmButton: false,
                timer: 2000
            });            
        }


    }
    const settingOpen = () => {
        setShowSetting(true)
    }
    const settingClose = () => {
        setShowSetting(false)
    }

    const setSettingForm = (e) => {
        console.log("e.target.name",e.target.name);
        switch (e.target.name) {
            case 'latar':
                setSettingInput({
                    ...settingInput,
                    [e.target.name]: e.target.files[0],
                });        
            break;
            default:
                setSettingInput({
                    ...settingInput,
                    [e.target.name]: e.target.value,
                });        
            break;
        }

        console.log("settingInput",settingInput);
    }

    const settingSave = () => {
        console.log("settingInput",settingInput);
        var formdata = new FormData();
        Object.entries(settingInput).map(([key, value]) =>
            formdata.append(key,value)
        );           
        formdata.append('aksi','setting');     
        formdata.append('subaksi','simpan');     
        
        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: formdata,
            redirect:'follow'
        };        
        try {
            fetch('https://pameran.apps.unismuh.ac.id/peserta.php',requestOptions).then(async (resp)=>(resp.json()).then(data=>{
                console.log("data",data);
                fetchSetting();
                Swal.fire({
                    title: "Sukses",
                    text: "Data berhasil disimpan",
                    icon: "success"
                });

            }))
        } catch (error) {
            Swal.showValidationMessage(`
              Request failed: ${error}
            `);
        }        
    }
    const handleDelete = (targetKey) => {
        Swal.fire({
            title: 'Anda yakin?',
            text: "Data yang di hapus tidak dapat dikembalikan!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal'
        }).then((result) => {
            if (result.isConfirmed) {
                console.log("Deleting target:", targetKey);
                const updatedWinners = winner.filter(item => item.id !== targetKey);
                setWinner(updatedWinners);
                console.log("Updated winners:", updatedWinners);
            }
        })
    }


  return (
    <>
        <div style={pageStyle} className="container-fluid">        
            <div className="row justify-content-center p-2" style={{backgroundColor:'#ffddaa44'}}>
                <div className="col-md-1 tombol-reset"  style={{display:'none'}}>
                    <button className='btn btn-danger w-100' onClick={reset}>RESET</button>                                
                </div>
                <div className="col-md-1 tombol-mulai" style={{display:''}}>
                    <button className='btn btn-primary w-100' onClick={putar}>MULAI</button>                                
                </div>
                <div className="col-md-1 ">
                    <button className='btn btn-success w-100' onClick={simpan}>SIMPAN</button>                                
                </div>
                <div className="col-md-1 mr-3 ms-auto">
                    <button className='btn btn-success w-100' onClick={settingOpen}>SETTING</button>                                
                </div>
                <div className="col-md-1">
                    <button className='btn btn-warning w-100' onClick={()=>navigate('/')}>BERANDA</button>                                
                </div>
            </div>


            <div className="row" style={{ marginTop:'20vh',fontSize:'12rem' }} id="number_roller">
                <div className='col-md-12'>
                            <section>
                                <div className="rt-container">
                                    <div className="col-rt-12">
                                        <div className="Scriptcontent">
                                                <div className="numbers">
                                                    <div className='row justify-content-center'>
                                                            <div className='col-md-12'>
                                                        {Array.from({ length: settingInput.jumlah_digit }).map((_, index) => (
                                                                <span className="numbers__window">
                                                                    <span id={`digit-${index+1}`} className="numbers__window__digit numbers__window__digit--1" data-fake={`${fakeNumber[index]}`}>0</span>
                                                                </span>
                                                            ))}
                                                            </div>

                                                    </div>
                                                </div>                                    
                                        </div>
                                    </div>

                                </div>
                            </section>

                </div>
            </div>
            <div className="row" style={{ flex: '1', marginBottom: 'auto',marginTop:'5vh'}}>
                <h1 style={{fontFamily:'fantasy',fontSize:'3rem',display:'none'}} id="judul_winner">DAFTAR PEMENANG UNDIAN</h1>
                {winner.length > 1  ? 
                    winner.map((item)=>(
                        <DisplayWinner
                            nama={item.nama_peserta} 
                            kode={item.rfid}
                            ktp={item.kode_peserta}
                            id={item.id}
                            key={item.id}
                            onDelete={()=>handleDelete(item.id)}                            
                        />
                    ))                
                : 
                winner.map((item)=>(
                    <DisplayWinnerSatu
                        nama={item.nama_peserta} 
                        kode={item.rfid}
                        ktp={item.kode_peserta}
                        id={item.id}
                        key={item.id}
                        onDelete={()=>handleDelete(item.id)}                            
                    />
                ))                
            }
            </div>


            <div className="row">
                <div className="col-md-2 ms-auto">
                    <div className="card">
                        <div className="card-body">
                            SIMUT-ICT 2024
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <Modal show={showSetting} onHide={settingClose}>
            <Modal.Header closeButton>
                <Modal.Title>Setup Undian</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Jumlah Undian</Form.Label>
                        <Form.Control
                            type="number"
                            id="jumlah_undian"
                            name="jumlah_undian"
                            defaultValue={settingInput.jumlah_undian}
                            onInput={setSettingForm}
                        />                        
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Jumlah Digit</Form.Label>
                        <Form.Control
                            type="number"
                            id="jumlah_digit"
                            name="jumlah_digit"
                            defaultValue={settingInput.jumlah_digit}
                            onInput={setSettingForm}
                        />                        
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Nama Kegiatan</Form.Label>
                        <Form.Control
                            type="text"
                            id="nama_kegiatan"
                            name="nama_kegiatan"
                            defaultValue={settingInput.nama_kegiatan}
                            onInput={setSettingForm}
                        />                        
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Latar</Form.Label>
                        <Form.Control 
                            type="file" 
                            accept='image/*' 
                            id='latar'
                            name='latar'
                            onInput={setSettingForm}
                        />                        
                    </Form.Group>
                </Form>                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={settingClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={settingSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    

  );
};

export default UndianMulti;
